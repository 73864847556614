import { Card, CardActionArea, Stack } from '@mui/material'
import {
    CoreTypography,
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { useCallback } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import AchievementIcon from '../../../components/icons/achievementsV2/AchievementIcon'
import { AchievementIcons } from '../../../enums'
import { promptSelectedDefaultValues } from '../../../utils/Avo/defaultValues'

const MICROSTEPS_PATH = '/today/microsteps'
const RESETS_PATH = '/reset'

const messages = defineMessages({
    cardTitle: {
        defaultMessage: 'Water your plant',
        description: 'card title for actions to water your plant'
    },
    completeMicrostep: {
        defaultMessage: 'Complete a Microstep',
        description: 'button text for completing a microstep'
    },
    watchReset: {
        defaultMessage: 'Watch a Reset',
        description: 'button text for watching a reset'
    }
})

type ActionItemProps = {
    text: string
    icon: AchievementIcons
    onClick: () => void
}

export const ActionItem: React.FC<ActionItemProps> = ({
    text,
    icon,
    onClick
}) => {
    const id = `${icon}-action-item`

    return (
        <Card variant="outlined">
            <CardActionArea onClick={onClick}>
                <Stack
                    px={2}
                    py={1}
                    flex={1}
                    minHeight={58}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Stack
                        gap={2}
                        flex={1}
                        width="100%"
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Stack
                            gap={1.5}
                            width="100%"
                            direction="row"
                            alignItems="center"
                        >
                            {<AchievementIcon achievementIcon={icon} />}
                            <CoreTypography variant="body1" id={id}>
                                {text}
                            </CoreTypography>
                        </Stack>
                        <LeafIcon
                            icon="arrow-right"
                            color="primary"
                            fontSize="small"
                        />
                    </Stack>
                </Stack>
            </CardActionArea>
        </Card>
    )
}

type WaterPlantCardProps = {
    onActionClick?: () => void
}

const WaterPlantCard: React.FC<WaterPlantCardProps> = ({ onActionClick }) => {
    const history = useHistory()
    const location = useLocation()
    const { formatMessage } = useIntl()
    const { palette } = useTheme()

    const handleClickMicrostep = useCallback(() => {
        onActionClick?.()
        Avo.promptSelected({
            featureType: 'achievement',
            activityType: 'complete_microstep_selected',
            checkInDate: new Date().toISOString(),
            isAutomaticCheckin: false,
            microstepBody: 'Complete a Microstep',
            microstepId: 'complete_microstep',
            microstepName: 'Complete a Microstep',
            nudgeType: undefined,
            ...promptSelectedDefaultValues
        })
        if (location.pathname !== MICROSTEPS_PATH) {
            history.push(MICROSTEPS_PATH)
        }
    }, [history, location.pathname, onActionClick])

    const handleClickReset = useCallback(() => {
        onActionClick?.()
        Avo.promptSelected({
            featureType: 'achievement',
            activityType: 'watch_reset_selected',
            checkInDate: new Date().toISOString(),
            isAutomaticCheckin: false,
            microstepBody: 'Complete a Microstep',
            microstepId: 'complete_microstep',
            microstepName: 'Complete a Microstep',
            nudgeType: undefined,
            ...promptSelectedDefaultValues
        })
        if (location.pathname !== RESETS_PATH) {
            history.push(RESETS_PATH)
        }
    }, [history, location.pathname, onActionClick])

    return (
        <Stack minWidth={280} minHeight={168} gap={2}>
            <CoreTypography variant="overline" color={palette.text.primary}>
                {formatMessage(messages.cardTitle)}
            </CoreTypography>
            <Stack gap={1}>
                <ActionItem
                    text={formatMessage(messages.completeMicrostep)}
                    icon={AchievementIcons.microstepper}
                    onClick={handleClickMicrostep}
                />
                <ActionItem
                    text={formatMessage(messages.watchReset)}
                    icon={AchievementIcons.mindfulMoments}
                    onClick={handleClickReset}
                />
            </Stack>
        </Stack>
    )
}

export default WaterPlantCard

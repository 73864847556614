import { defineMessages, defineMessage } from 'react-intl'

export const BUTTON_MESSAGES = defineMessages({
    viewGarden: {
        defaultMessage: 'View your garden',
        description: 'button text for viewing garden'
    },
    growFirstPlant: {
        defaultMessage: 'Grow your first plant',
        description: 'button text for navigating to the plant shop'
    },
    growAnotherPlant: {
        defaultMessage: 'Grow another plant',
        description: 'button text for navigating to the plant shop'
    }
})

export const SUCCESS_MESSAGES = defineMessages({
    justOnboarded: {
        defaultMessage: 'Nice work, you grew a plant by completing onboarding!',
        description:
            'text that appears when user is growing no plants, and has not grown plants in the past'
    },
    fullyGrown: {
        defaultMessage: 'Congratulations, your plant is fully grown!',
        description:
            'text that appears when user is has recently completed growing their plant'
    },
    fullyGrownAlt: {
        defaultMessage: 'Your plant is fully grown!',
        description: 'text shown when plant is fully grown'
    }
})

export const EARN_TOKENS_ACHIEVEMENTS_MESSAGE = defineMessage({
    defaultMessage: 'Earn more tokens by making progress on your Achievements.',
    description: 'message for not enough tokens to grow plant'
})

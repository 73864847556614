import {
    fetchAndStoreGarden,
    notifyError,
    setActivePlants,
    setGardenActionDisabled,
    setPlantedPlants,
    useAppSelector
} from '@thriveglobal/thrive-web-core'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import cloneDeep from 'lodash/cloneDeep'
import { type ComponentType, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PlantErrors } from '../../errors/plant'
import {
    type ProductInfo,
    type UserPlantInfo,
    usePurchaseProductMutation
} from '../../graphql/generated/autogenerated'
import useErrorHandling from '../useErrorHandling/useErrorHandling'
import PlantsProviderContext, {
    type PlantsProviderValue
} from './plantsProviderContext'

export default function withPlantsProvider<Props>(
    Component: ComponentType<Props>
) {
    return (props: Props): JSX.Element => {
        const { generateErrorNotification } = useErrorHandling()

        // States
        const dispatch = useDispatch()
        const [newlyPlanted, setNewlyPlanted] = useState<boolean>(true)

        // Queries / Mutations
        const [purchaseProductMutation] = usePurchaseProductMutation()

        const {
            activePlants = [],
            plantedPlants = [],
            products = [],
            objects = [],
            isLoading,
            isActionDisabled
        } = useAppSelector((state) => state.garden)

        const getPlantProduct = useCallback(
            (plant: UserPlantInfo) => {
                return products.find((p) => p?.upc === plant?.productUpc)
            },
            [products]
        )

        const updateActivePlants = useCallback(
            (plantId: string, revert = false) => {
                // Optimistic Update
                const plants = cloneDeep(activePlants)
                const num = revert ? 0 : 1
                const plant = plants.find((p) => p.id === plantId)
                plant.progressSteps += num
                plant.canBeWatered = false
                dispatch(setActivePlants(plants))
            },
            [activePlants, dispatch]
        )

        const updatePlantedPlants = useCallback(
            (plant: UserPlantInfo, revert = false) => {
                // Optimistic Update
                if (revert === true) {
                    const plants = cloneDeep(plantedPlants)
                    plants.filter((p) => p.id !== plant.id)
                    dispatch(setPlantedPlants(plants))
                    dispatch(setActivePlants([plant]))
                } else {
                    dispatch(setActivePlants([]))
                    dispatch(setPlantedPlants([...plantedPlants, plant]))
                }
            },
            [plantedPlants, dispatch]
        )

        const updatePurchasedPlants = useCallback(
            (plant: UserPlantInfo, revert = false) => {
                // Optimistic Update
                if (revert === true) {
                    dispatch(setActivePlants([]))
                } else {
                    dispatch(setActivePlants([plant]))
                }
            },
            [dispatch]
        )

        const handlePlantError = useCallback(
            (
                error: string,
                plant: UserPlantInfo,
                shouldUpdatePlants: boolean,
                shouldUpdatePlantedPlants: boolean,
                shouldUpdatePurchasedPlants: boolean
            ) => {
                if (error) {
                    // Revert on error
                    if (shouldUpdatePlants) {
                        updateActivePlants(plant?.id, true)
                    }

                    if (shouldUpdatePlantedPlants) {
                        updatePlantedPlants(plant, true)
                    }

                    if (shouldUpdatePurchasedPlants) {
                        updatePurchasedPlants(plant, true)
                    }

                    notifyError(generateErrorNotification(PlantErrors, error))

                    return true
                }

                return false
            },
            [
                updateActivePlants,
                updatePlantedPlants,
                updatePurchasedPlants,
                generateErrorNotification
            ]
        )

        const handlePlantMutation = useCallback(
            (
                plant: UserPlantInfo,
                mutation: () => Promise<any>,
                plantError: (response: any) => string,
                onSuccess: (response?: any) => void,
                updatePlants: boolean,
                updatePlantedPlants: boolean,
                updatePurchasedPlants: boolean
            ) => {
                return new Promise((resolve, reject) => {
                    mutation()
                        .then((response: any) => {
                            const responseError = plantError(response)
                            if (
                                handlePlantError(
                                    responseError,
                                    plant,
                                    updatePlants,
                                    updatePlantedPlants,
                                    updatePurchasedPlants
                                )
                            ) {
                                reject(responseError)
                            } else {
                                onSuccess(response)
                                resolve(response)
                            }
                        })
                        .catch((error: Error) => {
                            handlePlantError(
                                error.message,
                                plant,
                                updatePlants,
                                updatePlantedPlants,
                                updatePurchasedPlants
                            )
                            reject(error)
                        })
                        .finally(() => fetchAndStoreGarden())
                })
            },
            [handlePlantError]
        )

        const purchaseProduct = useCallback(
            (product: ProductInfo) => {
                // Optimistic Update
                const plant: UserPlantInfo = {
                    id: undefined,
                    canBeWatered: true,
                    __typename: 'UserPlantInfo',
                    productUpc: product.upc,
                    progressSteps: 0,
                    createdAt: undefined,
                    updatedAt: undefined,
                    userId: undefined
                } as UserPlantInfo
                updatePurchasedPlants(plant)
                dispatch(setActivePlants([...activePlants, plant]))
                dispatch(setGardenActionDisabled(true))
                // Mutation
                return handlePlantMutation(
                    null,
                    () =>
                        purchaseProductMutation({
                            variables: { upc: product.upc }
                        }),
                    (response) => response.data.retail.purchaseProduct['error'],
                    (response) => {
                        Avo.plantStarted({
                            activityType: 'garden_planted',
                            featureType: 'achievement',
                            plantId: product.upc,
                            plantType: product.upc
                        })
                    },
                    false,
                    false,
                    true
                )
            },
            [
                updatePurchasedPlants,
                handlePlantMutation,
                purchaseProductMutation,
                activePlants,
                dispatch
            ]
        )

        const state = useMemo<PlantsProviderValue>(
            () => ({
                refetch: fetchAndStoreGarden,
                isLoading,
                isActionDisabled,
                activePlants,
                plantedPlants,
                objects,
                products,
                newlyPlanted,
                purchaseProduct,
                getPlantProduct
            }),
            [
                isLoading,
                isActionDisabled,
                activePlants,
                plantedPlants,
                objects,
                products,
                newlyPlanted,
                purchaseProduct,
                getPlantProduct
            ]
        )

        return (
            <PlantsProviderContext.Provider value={state}>
                <Component {...props} />
            </PlantsProviderContext.Provider>
        )
    }
}

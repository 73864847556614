// TODO: Delete when the plant card component is removed

import { Grow, Stack } from '@mui/material'
import {
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { UserPlantInfo } from '../../../../graphql/generated/autogenerated'
import { usePlantsContext } from '../../../../hooks/usePlantsProvider'
import PlantCircularProgress from '../PlantCircularProgress/PlantCircularProgress'
import useAchievementAccessibilityDescriptions from '../../../../hooks/useAchievementAccessibilityDescriptions/useAchievementAccessibilityDescriptions'
import { AchievementAccessibilityDescriptions } from '../../../../enums/achievementAccessibilityDescriptions'

const messages = defineMessages({
    showDetails: {
        defaultMessage: 'show {plantName} details',
        description: 'button text'
    },
    waterLeft: {
        defaultMessage: 'show {plantName} details',
        description: 'button text'
    }
})

export interface PlantCardActiveProps {
    plantComponent: React.ReactNode
    completedSteps?: number
    totalSteps?: number
    isLarge?: boolean
    isEmpty?: boolean
    isActive?: boolean
    isWidget?: boolean
    onShowDetails?: () => void
    onCompletePlant?: () => void
}

const PlantCardActive: React.FC<PlantCardActiveProps> = ({
    plantComponent,
    completedSteps,
    totalSteps,
    isLarge,
    isEmpty,
    isActive,
    isWidget,
    onShowDetails,
    onCompletePlant
}) => {
    const { formatMessage } = useIntl()
    const { spacing } = useTheme()
    const ref = useRef(null)
    const { activePlants, products } = usePlantsContext()
    const descriptions = useAchievementAccessibilityDescriptions()

    const [isActionButtonLoading, setIsActionButtonLoading] =
        useState<boolean>(false)

    const { myPlant, myPlantProduct } = useMemo(
        () => ({
            myPlant: activePlants[0],
            myPlantProduct: products?.find(
                (p) => p.upc === activePlants[0]?.productUpc
            )
        }),
        [activePlants, products]
    )

    useEffect(() => {
        if (ref?.current) {
            ref.current.focus()
        }
    }, [ref])

    return (
        <Stack
            position="relative"
            height="100%"
            width="100%"
            p={isWidget ? 4 : 0}
            data-testid="plant-card-active"
        >
            {plantComponent && (
                <Grow in>
                    <Stack
                        position="relative"
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Stack
                            gap={2}
                            alignItems="center"
                            justifyContent="center"
                            height={isEmpty ? 'fit-content' : '100%'}
                            pt={isEmpty && isLarge ? 8 : isEmpty ? 2 : 0}
                        >
                            <Stack height="100%">{plantComponent}</Stack>
                        </Stack>
                    </Stack>
                </Grow>
            )}
            <Stack>
                {isActive && !isEmpty && (
                    <Stack
                        position="absolute"
                        top={isLarge ? spacing(2.5) : spacing(1)}
                        right={isLarge ? spacing(2) : spacing(1)}
                    >
                        <IconButton
                            variant="contained"
                            color="secondary"
                            onClick={() => onShowDetails()}
                            aria-label={formatMessage(messages.showDetails, {
                                plantName: myPlantProduct?.title
                            })}
                            ref={ref}
                        >
                            <LeafIcon icon={'circle-info'} />
                        </IconButton>
                    </Stack>
                )}
            </Stack>
            {completedSteps >= 0 && totalSteps >= 0 && (
                <Stack
                    position="absolute"
                    bottom={isLarge ? spacing(2.5) : spacing(1)}
                    right={isLarge ? spacing(2) : spacing(1)}
                >
                    <PlantCircularProgress
                        completedSteps={completedSteps}
                        totalSteps={totalSteps}
                        isLarge={isLarge}
                        ariaLabel={descriptions[
                            AchievementAccessibilityDescriptions
                                .ActivePlantStatus
                        ]({
                            hasActivePlant: true,
                            plantName: myPlantProduct?.title,
                            daysLeft: totalSteps - completedSteps
                        })}
                    />
                </Stack>
            )}
        </Stack>
    )
}

export default memo(PlantCardActive)

import { createContext } from 'react'
import {
    type ProductInfo,
    type UserPlantInfo,
    type UserObjectInfo
} from '../../graphql/generated/autogenerated'

export interface PlantsProviderValue {
    isLoading: boolean
    isActionDisabled: boolean
    activePlants: UserPlantInfo[]
    plantedPlants: UserPlantInfo[]
    products: ProductInfo[]
    objects: UserObjectInfo[]
    newlyPlanted: boolean
    refetch: () => Promise<any>
    purchaseProduct: (product: ProductInfo) => Promise<any>
    getPlantProduct: (plant: UserPlantInfo) => ProductInfo | undefined
}

export const defaultContextValue: PlantsProviderValue = {
    isLoading: false,
    isActionDisabled: false,
    activePlants: [],
    plantedPlants: [],
    products: [],
    objects: [],
    newlyPlanted: false,
    refetch: () => Promise.resolve(null),
    purchaseProduct: () => Promise.resolve(),
    getPlantProduct: () => undefined
}

export default createContext(defaultContextValue)
